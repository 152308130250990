// Bootstrap overrides

//
// Color system
//

$white: #fff !default;
$gray-base: #181b1e !default;
$gray-100: #f0f3f5 !default; // lighten($gray-base, 85%);
$gray-200: #e4e7ea !default; // lighten($gray-base, 80%);
$gray-300: #c8ced3 !default; // lighten($gray-base, 70%);
$gray-400: #acb4bc !default; // lighten($gray-base, 60%);
$gray-500: #8f9ba6 !default; // lighten($gray-base, 50%);
$gray-600: #73818f !default; // lighten($gray-base, 40%);
$gray-700: #5c6873 !default; // lighten($gray-base, 30%);
$gray-800: #2f353a !default; // lighten($gray-base, 10%);
$gray-900: #23282c !default; // lighten($gray-base, 5%);
$black: #000 !default;

$blue: #20a8d8;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #f86c6b;
$orange: #f8cb00;
$yellow: #ffc107 !default;
$green: #4dbd74;
$teal: #20c997 !default;
$cyan: #63c2de;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
);

$theme-colors: (
  primary: $blue,
  secondary: $gray-300,
  success: $green,
  info: $cyan,
  warning: $orange,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
  default: $white
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: false;

// Body
//
// Settings for the `<body>` element.

$body-bg: #e4e5e6;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.875rem;

// Breadcrumbs

$breadcrumb-bg: #fff;
$breadcrumb-padding-y: 1rem;
$breadcrumb-padding-x: 1.25rem;

// Cards

$card-border-color: $gray-200;
$card-cap-bg: $gray-100;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;

// Buttons

$btn-secondary-border: $gray-300;

// Progress bars

$progress-bg: $gray-100;

// Tables

$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;

// Forms

$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;
