// Here you can add other styles
.ngx-datatable {
  overflow: visible !important;
}

.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection
  .datatable-body-row.active
  .datatable-row-group {
  background-color: initial;
  &:hover,
  &:focus {
    background-color: #eee;
  }
}

.ngx-datatable.material {
  .datatable-body-cell-label,
  .datatable-header-cell,
  .datatable-footer .page-count {
    cursor: default;
  }
}

.ngx-datatable.material
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  font-size: 12px;
  font-weight: 400;
}

.ngx-datatable.material {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  .datatable-header {
    .datatable-header-cell-label {
      font-size: 14px;
    }
  }

  .datatable-footer {
    background-color: #f1f3f8;
    font-size: 14px;
    font-weight: 600;
  }

  &.add-header-background
    .datatable-header
    .datatable-header-inner
    .datatable-header-cell {
    background-color: #f1f3f8;
  }

  .datatable-header .datatable-header-inner .datatable-header-cell {
    color: #23282c;
    font-size: 14px;
  }

  .datatable-row-wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    &:last-of-type {
      border-bottom: none;
    }
  }
}

div,
span,
p {
  cursor: default;
}

.sm-image-container {
  width: 80%;
  height: 80%;
  text-align: center;
  background-color: white;
  overflow: hidden;
  .sm-image {
    max-width: 80%;
    max-height: 80%;
  }
}

.medium-zoom-overlay {
  z-index: 9999 !important;
  opacity: 0.6 !important;
}
.medium-zoom-image {
  z-index: 10000 !important;
}

.checkbox-btn {
  &.form-group input[type="checkbox"] {
    display: none;
    + .btn-group > .btn:first-child {
      display: none;
    }
    + .btn-group > .btn:last-child {
      width: 226px;
    }
    + .btn-group > label {
      &:first-child span {
        width: 20px;
      }
      span {
        &:first-child {
          display: none;
        }
        &:last-child {
          display: inline-block;
        }
      }
    }
    &:checked {
      + .btn-group > label span {
        &:first-child {
          display: inline-block;
        }
        &:last-child {
          display: none;
        }
      }
      + .btn-group > .btn:first-child {
        color: $green;
        opacity: 1;
        display: inline-block;
      }
      + .btn-group > .btn:last-child {
        background-color: $green;
        color: $white;
        width: 180px;
      }
    }
  }
}

.joma-form-section {
  margin-top: 1rem;
  background-color: #bbc0c5;
  color: white;
  padding: 10px;
  margin-bottom: 0.5rem;
}

formly-form.row {
  padding: 0.5rem 1rem;
}

.nav-title {
  margin-top: 0.75rem;
}

.form-control {
  color: #333;
  border-color: #ccc;
  border-radius: 4px;
  min-height: 36px;
}

label {
  font-size: 1rem;
}

.search-clear {
  position: absolute;
  z-index: 10;
  right: 100px;
  top: 10px;
  font-size: 16px;
  cursor: pointer;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

.panel {
  background-color: #fff;
  border: 1px solid #ccc;

  .panel-header {
    border-bottom: 1px solid #ccc;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.accent-danger {
  border-top-width: 2px;
  border-top-color: #f86c6b;
  border-top-style: solid;
}

.ng-select.ng-select-disabled > .ng-select-container {
  cursor: not-allowed;
  background-color: #e4e7ea;
  opacity: 1;
}

input:disabled {
  cursor: not-allowed !important;
}

.clickable {
  cursor: pointer !important;
  * {
    cursor: pointer !important;
  }
}

.ladda-label {
  cursor: pointer;
}
