.card {
  margin-bottom: 1.5 * $spacer;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;

  // Cards with color accent
  @each $color, $value in $theme-colors {
    &.bg-#{$color} {
      border-color: darken($value, 12.5%);
      .card-header {
        background-color: darken($value, 3%);
        border-color: darken($value, 12.5%);
      }
    }
  }
}

.text-white .text-muted {
  color: rgba(255, 255, 255, 0.6) !important;
}

.card-header {
  &:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
  .icon-bg {
    display: inline-body;
    padding: $card-spacer-y $card-spacer-x !important;
    margin-top: -$card-spacer-y;
    margin-right: $card-spacer-x;
    margin-bottom: -$card-spacer-y;
    margin-left: -$card-spacer-x;
    line-height: inherit;
    color: $card-icon-color;
    vertical-align: bottom;
    background: $card-icon-bg;
    border-right: $card-border-width solid $card-border-color;
  }

  .nav.nav-tabs {
    margin-top: -$card-spacer-y;
    margin-bottom: -$card-spacer-y;
    border-bottom: 0;

    .nav-item {
      border-top: 0;
    }

    .nav-link {
      padding: $card-spacer-y $card-spacer-x * 0.5;
      color: $text-muted;
      border-top: 0;

      &.active {
        color: $body-color;
        background: #fff;
      }
    }
  }

  &.card-header-inverse {
    color: #fff;
  }

  .btn {
    margin-top: -$input-btn-padding-y;
  }
  .btn-sm {
    margin-top: -$input-btn-padding-y-sm;
  }
  .btn-lg {
    margin-top: -$input-btn-padding-y-lg;
  }
}
//
.card-footer {
  ul {
    display: table;
    width: 100%;
    padding: 0;
    margin: 0;
    table-layout: fixed;

    li {
      display: table-cell;
      padding: 0 $card-spacer-x;
      text-align: center;
    }
  }
}

[class*="card-outline-"] {
  .card-body {
    background: #fff !important;
  }

  &.card-outline-top {
    border-top-width: 2px;
    border-right-color: $border-color;
    border-bottom-color: $border-color;
    border-left-color: $border-color;
  }
}

// Cards with color accent
@each $color, $value in $theme-colors {
  .card-accent-#{$color} {
    @include card-accent-variant($value);
  }
}

// Card Actions
.card-header {
  > i {
    margin-right: math.div($spacer, 2);
  }
  .card-actions {
    position: absolute;
    top: 0;
    right: 0;
    //height: inherit;

    a,
    button {
      display: block;
      float: left;
      width: 50px;
      padding: $card-spacer-y 0;
      margin: 0 !important;
      color: $body-color;
      text-align: center;
      background: transparent;
      border: 0;
      border-left: 1px solid $border-color;
      box-shadow: 0;

      &:hover {
        text-decoration: none;
      }

      [class^="icon-"],
      [class*=" icon-"] {
        display: inline-body;
        vertical-align: middle;
      }

      i {
        display: inline-body;
        transition: 0.4s;
      }

      .r180 {
        transform: rotate(180deg);
      }
    }

    .input-group {
      width: 230px;
      margin: 6px;

      .input-group-addon {
        background: #fff;
      }

      input {
        border-left: 0;
      }
    }
  }
}

.card-header-actions {
  display: inline-block;
  float: right;
  margin-right: -0.25rem;
}

.card-header-action {
  padding: 0 0.25rem;
  color: #73818f;
}

.card-header-action:hover {
  color: #23282c;
  text-decoration: none;
}

.card-full {
  margin-top: -$spacer;
  margin-right: -$grid-gutter-width * 0.5;
  margin-left: -$grid-gutter-width * 0.5;
  border: 0;
  border-bottom: $card-border-width solid $border-color;
}

@include media-breakpoint-up(sm) {
  .card-columns {
    &.cols-2 {
      column-count: 2;
    }
  }
}

.card {
  &.drag,
  .drag {
    cursor: move;
  }
}

.card-placeholder {
  background: rgba(0, 0, 0, 0.025);
  border: 1px dashed $gray-300;
}
